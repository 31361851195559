<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              depressed
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <div>
              <div class="tt-text-title-1">
                {{ team.name }}
              </div>
              <div class="tt-text-body-2 tt-light-mono-46--text">
                {{ team.id }}
              </div>
            </div>
          </VToolbar>

          <VDivider />

          <VCardText>
            <VRow>
              <VCol>
                <VExpansionPanels
                  accordion
                  flat
                >
                  <VExpansionPanel>
                    <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
                    <VExpansionPanelContent>
                      <code class="elevation-0 d-block">{{ team }}</code>
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>
              </VCol>
            </VRow>

            <VDivider />

            <VRow>
              <VCol>
                <VDataTable
                  :items="fullStaffPositions"
                  :headers="staffPositionsHeaders"
                >
                  <template #top>
                    <VRow class="mb-3">
                      <VCol>
                        <h5 class="text-h5 font-weight-bold">
                          Штатные должности
                        </h5>
                      </VCol>
                      <VCol class="align-self-center text-end">
                        <VBtn
                          color="primary"
                          :to="{ name : Names.R_ORGSTRUCTURE_POSITION_CREATE,
                                 query : { teamId : team.id }
                          }"
                        >
                          <VIcon left>
                            fal fa-plus
                          </VIcon>

                          Добавить должность
                        </VBtn>
                      </VCol>
                    </VRow>
                  </template>
                  <template #item.name="{item : {id, name}}">
                    <RouterLink :to="{ name : Names.R_ORGSTRUCTURE_POSITION_EDIT, params : { positionId : id } }">
                      <div>
                        {{ name }}
                      </div>
                    </RouterLink>
                  </template>

                  <template #item.userFullName="{item : {userId, userFullName, isFree}}">
                    <RouterLink
                      v-if="!isFree"
                      :to="{ name : Names.R_AUTHENTICATION_USER, params : { userId } }"
                    >
                      <div>
                        {{ userFullName }}
                      </div>
                    </RouterLink>
                    <div v-else>
                      {{ userFullName }}
                    </div>
                  </template>

                  <template #item.actions="{item}">
                    <VTooltip left>
                      <template #activator="{ on, attrs }">
                        <VBtn
                          icon
                          small
                          color="red"
                          class="mx-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteStaffPosition(item)"
                        >
                          <VIcon small>
                            fal fa-trash
                          </VIcon>
                        </VBtn>
                      </template>

                      <span>Удаление</span>
                    </VTooltip>
                  </template>
                </VDataTable>

                <VDialog
                  v-model="confirmDialog"
                  max-width="500px"
                >
                  <VCard>
                    <VCardTitle class="headline">
                      Удалить
                    </VCardTitle>

                    <VCardText>
                      Штатная должность <span class="red--text">{{ deletedPosition.name }}</span> будет удалена! <br>
                      Действительно хотите удалить штатную должность?
                    </VCardText>

                    <VCardActions>
                      <VSpacer />

                      <VBtn
                        text
                        @click="handleCancel"
                      >
                        Нет
                      </VBtn>

                      <VBtn
                        color="red darken-1"
                        text
                        @click="handleDelete"
                      >
                        Да
                      </VBtn>
                    </VCardActions>
                  </VCard>
                </VDialog>
              </VCol>
            </VRow>

            <VDivider class="my-5" />

            <VRow>
              <VCol>
                <VDataTable
                  :items="teamChildren"
                  :headers="teamChildrenHeaders"
                >
                  <template #top>
                    <VRow class="mb-3">
                      <VCol>
                        <h5 class="text-h5 font-weight-bold">
                          Дочерние команды
                        </h5>
                      </VCol>
                      <VCol class="align-self-center text-end">
                        <VBtn
                          color="primary"
                          @click="addChildToTeam"
                        >
                          <VIcon left>
                            fal fa-plus
                          </VIcon>

                          Добавить дочернюю команду
                        </VBtn>
                      </VCol>
                    </VRow>
                  </template>

                  <template #item.name="{item}">
                    <RouterLink
                      :to="{
                        name : Names.R_ORGSTRUCTURE_COMPANY_TEAM,
                        params : {
                          companyId : item.companyId,
                          teamId : item.id
                        }
                      }"
                    >
                      <div>
                        {{ item.name }}
                      </div>
                    </RouterLink>
                  </template>

                  <template #item.id="{item}">
                    <div>
                      {{ item.id }}
                    </div>
                  </template>
                </VDataTable>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { chunk } from 'lodash';

export default {
  name: 'OrgTeam',

  inject: ['Names'],

  data() {
    return {
      team: {},
      company: {},
      users: {},
      staffPositions: [],
      confirmDialog: false,
      deletedPosition: {},
      teamChildren: [],
      staffPositionsHeaders: [
        {
          text: 'ID ШД',
          value: 'id',
        },
        {
          text: 'Название ШД',
          value: 'name',
        },
        {
          text: 'UUID сотрудника',
          value: 'userId',
        },
        {
          text: 'ФИО сотрудника',
          value: 'userFullName',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      teamChildrenHeaders: [
        {
          text: 'Название',
          value: 'name',
        },
        {
          text: 'id',
          value: 'id',
        },
      ],
    };
  },

  computed: {
    /**
     *
     * @returns {string}
     */
    companyId() {
      return this.$route.params.companyId;
    },
    /**
     *
     * @returns {string}
     */
    teamId() {
      return this.$route.params.teamId;
    },

    fullStaffPositions() {
      return this.staffPositions.map((position) => {
        let fullName = '';
        if (Object.keys(this.users).length > 0) {
          const currentUser = this.users[position.userId];
          fullName = `${currentUser?.lastName} ${currentUser?.firstName} ${currentUser?.middleName}`;
        }
        const userFullName = position.userId ? fullName : 'Свободно';

        return {
          ...position,
          userId: position.userId ? position.userId : 'Свободно',
          userFullName,
          isFree: !position.userId,
        };
      });
    },
  },

  watch: {
    $route: {
      handler(newValue) {
        const { companyId, teamId } = newValue.params;
        this.$di.api.Orgstructure
          .teamGet({ companyId, id: teamId })
          .then((r) => {
            this.team = r.team;
          })
          .catch((err) => {
            this.$di.notify.errorHandler(err);
            this.$di.redirect.errorHandler404(err);
          });

        this.getTeamStaffPositions();

        this.$di.api.Account
          .getCompany({ id: companyId })
          .then((r) => {
            this.company = r.company;
          })
          .catch((err) => {
            this.$di.notify.errorHandler(err);
            this.$di.redirect.errorHandler404(err);
          });

        this.$di.api.Orgstructure
          .teamChildren({ companyId, id: teamId })
          .then((r) => {
            this.teamChildren = r.teams;
          })
          .catch((err) => {
            this.$di.notify.errorHandler(err);
            this.$di.redirect.errorHandler404(err);
          });
      },
      immediate: true,
    },
  },

  methods: {
    getTeamStaffPositions() {
      this.$di.api.Orgstructure
        .getTeamStaffPositions({ teamId: this.teamId })
        .then((r) => {
          this.staffPositions = r.staffPositions.sort((a, b) => a.name?.localeCompare(b.name)) || [];
          this.getAllUsers();
        })
        .catch(this.$di.notify.errorHandler);
    },
    async getAllUsers() {
      const usersIds = this.staffPositions.reduce((acc, position) => {
        if (position.userId) {
          acc.push(position.userId);
        }
        return acc;
      }, []);

      // при запросе большого количества пользователей, в ручке GetUsersBatch наблюдаются просадки производительности
      // было принято решение запрашивать данные частями
      const chunkSize = 100;
      const userIdsChunks = chunk(usersIds, chunkSize);
      const usersList = [];

      await Promise.all(userIdsChunks.map(async (ids) => {
        try {
          const { users } = await this.$di.api.Authentication.getUsers({ ids });
          usersList.push(...users);
        } catch (e) {
          this.$di.notify.errorHandler(e);
          // TODO: добавить обработку ошибок и повторные запросы к ручке в случае неудачи
        }
      }));

      this.users = usersList.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
    },
    addChildToTeam() {
      this.$router.push({
        name: this.Names.R_ORGSTRUCTURE_COMPANY_TEAM_CHILDREN_ADD,
      });
    },
    deleteStaffPosition(item) {
      this.deletedPosition = item;
      this.confirmDialog = true;
    },

    handleCancel() {
      this.confirmDialog = false;
      this.deletedPosition = {};
    },

    async handleDelete() {
      try {
        await this.$di.api.Orgstructure.positionDelete({
          id: this.deletedPosition.id,
        });

        this.$di.notify.snackSuccess('Штатная должность удалена');
        this.handleCancel();
        this.getTeamStaffPositions();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
