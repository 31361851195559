var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VCard',[_c('VToolbar',{attrs:{"flat":""}},[_c('VBtn',{attrs:{"icon":"","depressed":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("fal fa-chevron-left")])],1),_c('div',[_c('div',{staticClass:"tt-text-title-1"},[_vm._v(" "+_vm._s(_vm.team.name)+" ")]),_c('div',{staticClass:"tt-text-body-2 tt-light-mono-46--text"},[_vm._v(" "+_vm._s(_vm.team.id)+" ")])])],1),_c('VDivider'),_c('VCardText',[_c('VRow',[_c('VCol',[_c('VExpansionPanels',{attrs:{"accordion":"","flat":""}},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("JSON")]),_c('VExpansionPanelContent',[_c('code',{staticClass:"elevation-0 d-block"},[_vm._v(_vm._s(_vm.team))])])],1)],1)],1)],1),_c('VDivider'),_c('VRow',[_c('VCol',[_c('VDataTable',{attrs:{"items":_vm.fullStaffPositions,"headers":_vm.staffPositionsHeaders},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VRow',{staticClass:"mb-3"},[_c('VCol',[_c('h5',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Штатные должности ")])]),_c('VCol',{staticClass:"align-self-center text-end"},[_c('VBtn',{attrs:{"color":"primary","to":{ name : _vm.Names.R_ORGSTRUCTURE_POSITION_CREATE,
                               query : { teamId : _vm.team.id }
                        }}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Добавить должность ")],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
                        var ref_item = ref.item;
                        var id = ref_item.id;
                        var name = ref_item.name;
return [_c('RouterLink',{attrs:{"to":{ name : _vm.Names.R_ORGSTRUCTURE_POSITION_EDIT, params : { positionId : id } }}},[_c('div',[_vm._v(" "+_vm._s(name)+" ")])])]}},{key:"item.userFullName",fn:function(ref){
                        var ref_item = ref.item;
                        var userId = ref_item.userId;
                        var userFullName = ref_item.userFullName;
                        var isFree = ref_item.isFree;
return [(!isFree)?_c('RouterLink',{attrs:{"to":{ name : _vm.Names.R_AUTHENTICATION_USER, params : { userId: userId } }}},[_c('div',[_vm._v(" "+_vm._s(userFullName)+" ")])]):_c('div',[_vm._v(" "+_vm._s(userFullName)+" ")])]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteStaffPosition(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление")])])]}}])}),_c('VDialog',{attrs:{"max-width":"500px"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить ")]),_c('VCardText',[_vm._v(" Штатная должность "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.deletedPosition.name))]),_vm._v(" будет удалена! "),_c('br'),_vm._v(" Действительно хотите удалить штатную должность? ")]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Нет ")]),_c('VBtn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.handleDelete}},[_vm._v(" Да ")])],1)],1)],1)],1)],1),_c('VDivider',{staticClass:"my-5"}),_c('VRow',[_c('VCol',[_c('VDataTable',{attrs:{"items":_vm.teamChildren,"headers":_vm.teamChildrenHeaders},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VRow',{staticClass:"mb-3"},[_c('VCol',[_c('h5',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Дочерние команды ")])]),_c('VCol',{staticClass:"align-self-center text-end"},[_c('VBtn',{attrs:{"color":"primary"},on:{"click":_vm.addChildToTeam}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Добавить дочернюю команду ")],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
                        var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{
                      name : _vm.Names.R_ORGSTRUCTURE_COMPANY_TEAM,
                      params : {
                        companyId : item.companyId,
                        teamId : item.id
                      }
                    }}},[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.id",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.id)+" ")])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }